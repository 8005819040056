<template>
  <section class="container">
    <layout-breadcrumb :items="breadcrumbs"></layout-breadcrumb>
    <div class="row">
      <div class="col-md-12 mb-3">
        <h2 class="font-weight-800 text-transparencia">
          <!-- <a @click="$router.go(-1)"><i class="fa fa-arrow-left cr-po mr-5" data-toggle="tooltip" data-placement="top" title="Volver"></i></a> -->
          VOLUNTARIADO
        </h2>
      </div>
    </div>
    <div class="row justify-content-center border-bottom">
      <div class="col-md-6">
        <h4 class="mb-3 fw-600 text-center mt-2 text-transparencia">
          {{ datos.titulo }}
        </h4>
        <div class="mt-3">
          <img :src="`${filesUrl + datos.rutaImagenRed}`" data-provide="lightbox" class="w-100 cr-po image-round-all" v-if="datos.tipoMultimediaRed == 'IMG'" />
          <div class="video-wrapper ratio-16x9" v-if="datos.tipoMultimediaRed == 'VID'">
            <iframe title="video" :src="datos.rutaVideoRed" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="" frameborder="0"></iframe>
          </div>
        </div>
        <p class="mb-5 mt-3 mr-3 text-justify text-transparencia" v-html="datos.descripcion"></p>
      </div>
      <div class="col-md-6">
        <h4 class="mb-3 fw-600 text-center mt-2 text-transparencia">
          {{ datos.tituloPerfil }}
        </h4>
        <div class="mt-3">
          <img :src="`${filesUrl + datos.rutaImagenPerfil}`" data-provide="lightbox" class="w-100 cr-po image-round-all" v-if="datos.tipoMultimediaPerfil == 'IMG'" style="height:355px !important;" />
          <div class="video-wrapper ratio-16x9" v-if="datos.tipoMultimediaPerfil == 'VID'">
            <iframe title="video" :src="datos.rutaVideoPerfil" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="" frameborder="0"></iframe>
          </div>
        </div>
        <p class="mb-5 mt-3 text-justify text-transparencia" v-html="datos.perfil"></p>
      </div>
    </div>
    <div class="row mt-5 justify-content-center">
      <div class="col-md-6">
        <h4 class="fw-600 mb-4 text-transparencia">
          Beneficios del Voluntariado
        </h4>
        <p class="text-justify text-transparencia" v-html="datos.beneficios"></p>
      </div>
      <div class="col-md-6">
        <div class=" pl-3 pr-3">
          <h4 class="fw-600 mb-4 text-transparencia">
            {{ datos.tituloVoluntariado }}
          </h4>
          <p class="text-transparencia">
            <span  class="text-transparencia" v-html="datos.descripcionVoluntariado"></span> 
          </p>
        </div>
      </div>
    </div>
    <div class="row justify-content-around">
      <div class="col-sm-6 col-xs-12 col-md-5 col-lg-5 mt-3">
        <a :href="'voluntariado/actividades'">
          <div class="media bg-transparencia px-5 py-6 image-round-all transition-hover hover-shadow-9">
            <div class="mr-5">
              <img :src="fileWebUrl('assets/images/actividades_amarillo.png')" alt="" class="img-fluid mt-2 img-vol-act" style="width: 5rem; height: auto" />
            </div>
            <div class="media-body  text-white">
              <h3 class="h5 mt-0 mb-2">Actividades</h3>
              <p class="mb-4">Conoce las actividades que realizamos con la comunidad de voluntarios y voluntarias.</p>
            </div>
          </div>
        </a>
      </div>
      <div class="col-sm-6 col-xs-12 col-md-5 col-lg-5 mt-3">
        <a :href="'voluntariado/coordinadores'">
        <div class="media bg-transparencia px-5 py-6 image-round-all transition-hover hover-shadow-9">
            <div class="mr-5">
              <img :src="fileWebUrl('assets/images/coordinadores_amarillo.png')" alt="" class="img-fluid mt-2 img-vol-act" style="width: 5rem; height: auto" />
            </div>
            <div class="media-body  text-white">
              <h3 class="h5 mt-0 mb-2">Coordinadores Regionales</h3>
              <p class="mb-4">Conoce a los coordinadores y coordinadoras de tu región.</p>
            </div>
          </div>
        </a>
      </div>
    </div>
    <!-- <div class="row justify-content-center mt-7">
      <div class="col-sm-12 col-xs-12 col-md-9 col-lg-9 text-center">
        <p class="text-dark">
          <span>¿Deseas formar parte de la Red Nacional de Voluntariado de Transparencia?</span><br />
          <span>Escribe al siguiente correo: voluntariado@transparencia.org.pe</span> <br />
          <span>Enviando tu nombre completo, región de residencia y datos de contacto (celular y/o correo electrónico).</span>
        </p>
      </div>
    </div> -->
  </section>
</template>

<script>
import layoutBreadcrumb from "@/layout/layout-breadcrumb";
export default {
  name: "view-voluntariado",
  metaInfo: {
    title: "Voluntariado",
  },
  data: () => ({
    datos: {},
  }),
  components: { layoutBreadcrumb },
  methods: {
    fetchDatos: async function() {
      await this.$http.get(this.apiRoute("Common", "GetVoluntariadoData")).then(function(response) {
        if (!response.body.error) {
          this.datos = response.body.data;
        }
      });
    },
  },
  mounted: async function() {
    await this.fetchDatos();

    this.breadcrumbs = [
      { name: "Inicio", url: "/home", icon: "fa fa-home", active: false },
      { name: "Voluntariado", url: "", icon: "", active: true },
    ];
  },
};
</script>
